<template>
  <page-layout id="myOrder">
    <a-card>
      <div class="classify_box">
        <div class="flex1">
          <span>售后类型：</span>
          <span class="classify">
            <span
              :class="query.refundType == 0 ? 'active' : ''"
              @click="(query.refundType = 0), clearPage(), getList()"
            >
              全部
            </span>
            <span
              :class="query.refundType == 1 ? 'active' : ''"
              @click="(query.refundType = 1), clearPage(), getList()"
            >
              仅退款
            </span>
            <span
              :class="query.refundType == 2 ? 'active' : ''"
              @click="(query.refundType = 2), clearPage(), getList()"
            >
              退货退款
            </span>
            <span
              :class="query.refundType == 100 ? 'active' : ''"
              @click="(query.refundType = 100), clearPage(), getList()"
            >
              售后驳回
            </span>
          </span>
        </div>
      </div>
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-form-model layout="inline" :model="query" @submit.native.prevent>
            <a-form-model-item label="申请时间">
              <a-range-picker
                style="width: 250px; margin-left: 16px"
                @change="changeDateTime"
              />
              <a-button
                type="primary"
                style="
                  margin-left: 16px;
                  vertical-align: middle;
                  margin-bottom: 4px;
                "
                @click="getQuery"
              >
                查询
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col>
          <a-input-search
            v-model="query.keyword"
            placeholder="输入订单编号/商品名称搜索"
            enter-button="搜索"
            style="width: 300px"
            @search="getList"
          />
        </a-col>
      </a-row>
    </a-card>
    <a-card style="margin-top: 12px">
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        class="table"
        :pagination="false"
        :scroll="{ x: 1300 }"
      >
        <template slot="orderId" slot-scope="text, record">
          <div style="text-align: left">
            <p>{{ record.order_id }}</p>
            <span
              class="order_type"
              :style="record.combo == 3 ? 'background-color:#ff8d1a;' : ''"
            >
              {{
                record.combo == 1
                  ? "单品商品"
                  : record.combo == 2
                  ? "单品套餐"
                  : "分箱套餐"
              }}
            </span>
          </div>
        </template>
        <template slot="product" slot-scope="text, record">
          <div
            v-for="(item, index) in record.productInfo"
            :key="index"
            class="product_box"
          >
            <img style="object-fit: contain" :src="item.image" alt="" />
            <div class="product_info">
              <p class="product_name">{{ item.store_name }}</p>
              <p class="product_num">
                <span>￥{{ item.price | price }}</span>
                <span>×{{ item.number }}</span>
              </p>
            </div>
          </div>
        </template>
        <template slot="type" slot-scope="text, record">
          {{ record.refund_type == 1 ? "仅退款" : "退货退款" }}
        </template>
        <template slot="orderStatus" slot-scope="text">
          <!-- 订单状态 0待付款  1已付款/待审核  2部分已发货  3待收货  4已完成  5已取消 6待发货 7已退款 -->
          {{
            text == 0
              ? "待付款"
              : text == 1
              ? "已付款/待审核"
              : text == 2
              ? "部分已发货"
              : text == 3
              ? "待收货"
              : text == 4
              ? "已完成"
              : text == 5
              ? "已取消"
              : text == 6
              ? "待发货"
              : "已退款"
          }}
        </template>
        <template slot="return_status" slot-scope="text">
          <!-- 1 申请中   2售后通过 待用户寄回  3售后驳回 4用户已寄回 待收货 5售后完成 6售后取消 7退款中 8退款失败 -->
          {{
            text == 1
              ? "申请中"
              : text == 2
              ? "售后通过，待寄回"
              : text == 3
              ? "售后驳回"
              : text == 4
              ? "商品已寄回，待商家确认"
              : text == 5
              ? "售后完成"
              : text == 6
              ? "售后取消"
              : text == 7
              ? "退款确认中"
              : "退款失败"
          }}
        </template>
        <template slot="price" slot-scope="text, record">
          <p>实退：￥{{ record.refund_price }}</p>
          <p>含运费：￥{{ record.freight }}</p>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="toDetail(record.id)">
            查看详情
          </a-button>
          <a-button
            v-if="
              record.return_status != 3 &&
              record.return_status != 5 &&
              record.return_status != 6
            "
            type="link"
            @click="cancelRefund(record)"
          >
            取消售后
          </a-button>
        </template>
      </a-table>
      <div style="margin-top: 20px; text-align: right">
        <a-pagination
          v-if="total != 0"
          v-model="query.page"
          :page-size-options="pageSizeOptions"
          :page-size="query.limit"
          :total="total"
          show-size-changer
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </a-card>

    <a-modal
      title="查看售后进度"
      :visible="progressModal"
      :footer="null"
      @cancel="handleCancel"
    >
      <div class="progress_box" v-if="refundDetails.return_status == 1">
        <a-row>
          <a-col :span="24" class="green_title">售后审核审核中</a-col>
          <a-col :span="24" class="green_content">{{
            refundDetails.add_time
          }}</a-col>
        </a-row>
      </div>
      <div
        class="progress_box"
        v-if="
          refundDetails.return_status != 1 && refundDetails.return_status != 3
        "
      >
        <a-row>
          <a-col :span="24" class="green_title">售后审核已通过</a-col>
          <a-col :span="24" class="green_content">{{
            refundDetails.audit_time
          }}</a-col>
        </a-row>
      </div>
      <div
        class="progress_box"
        v-if="
          refundDetails.return_status != 3 && refundDetails.return_status == 2
        "
      >
        <a-row>
          <a-col :span="24" class="green_title">请回寄商品</a-col>
          <a-col :span="24" class="green_title">
            <a-form-model
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="商品回寄信息" class="info">
                <p class="return_info">
                  <span>收货人：{{ refundDetails.warehouse_recipients }}</span>
                  <span>收货地址：{{ refundDetails.warehouse_address }}</span>
                  <span>收货人电话：{{ refundDetails.warehouse_phone }}</span>

                  <!-- <span>邮编：250000</span> -->
                </p>
                <!-- <p class="red_content">注意：请不要发到付或平邮，此处文字后台配置</p> -->
                <p class="red_content">
                  请您在72小时内录入单号，否则系统将自动关闭售后
                </p>
              </a-form-model-item>
              <div
                v-for="(way, index) in infoList"
                :key="index + ''"
                class="boder1"
              >
                <a-form-model-item label="选择快递公司">
                  <a-select
                    style="width: 100%"
                    v-model="way.name"
                    placeholder="请选择快递公司"
                    @change="(e) => selectName(e, index, 'name')"
                  >
                    <a-select-option
                      v-for="(item, i) in wayList"
                      :value="item.name"
                      :key="i + ''"
                      >{{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="输入快递单号">
                  <a-input
                    v-model="way.number"
                    placeholder="请输入快递单号"
                    @change="(e) => selectName(e, index, 'number')"
                  />
                </a-form-model-item>
                <div class="close">
                  <a-icon type="close-circle" @click="delInfo(index)" />
                </div>
              </div>
              <div class="add_info" @click="addInfo">+再添加一条快递信息</div>
              <div class="text_r">
                <a-button type="primary" @click="submitInfo">
                  提交回寄信息
                </a-button>
              </div>
            </a-form-model>
          </a-col>
        </a-row>
      </div>
      <div
        class="progress_box"
        v-if="
          refundDetails.return_status != 3 && refundDetails.return_status == 4
        "
      >
        <a-row>
          <a-col :span="24" class="green_title">商品已回寄</a-col>
          <a-col :span="24" class="green_content">{{
            refundDetails.return_time
          }}</a-col>
        </a-row>
      </div>
      <!-- <div class="progress_box" v-if="refundDetails.return_status == 5" >
                <a-row>
                    <a-col :span="24" class="green_title">仓库确认收货</a-col>
                    <a-col :span="24" class="green_content">2022-02-24 14:48:10</a-col>
                </a-row>
            </div> -->
      <div class="progress_box" v-if="refundDetails.return_status == 5">
        <a-row>
          <a-col :span="24" class="green_title">退款金额退回</a-col>
          <a-col :span="24" class="green_content">{{
            refundDetails.refunded_time
          }}</a-col>
          <a-col :span="24" class="red_content">
            退回商品金额：{{ refundDetails.refund_price }} 退回运费：¥{{
              refundDetails.freight
            }}
          </a-col>
          <a-col :span="24" class="content_color">
            注：退款金额将原路返回。
          </a-col>
        </a-row>
      </div>
      <div class="progress_box" v-if="refundDetails.return_status == 5">
        <a-row>
          <a-col :span="24" class="green_title">退款成功！</a-col>
          <a-col :span="24" class="green_content">{{
            refundDetails.refunded_time
          }}</a-col>
        </a-row>
      </div>
      <div
        class="progress_box red_cricle"
        v-if="refundDetails.return_status == 3"
      >
        <a-row>
          <a-col :span="24" class="red_title">退款失败！</a-col>
          <a-col :span="24" class="red_content">{{
            refundDetails.refunded_time
          }}</a-col>
          <a-col :span="24" class="content_color">
            拒绝理由：{{ refundDetails.refuse_reason }}
          </a-col>
          <a-col :span="24" class="content_color">
            重新发起请前往
            <router-link
              :to="'/bookBuy/order/orderDetail?id=' + refundDetails.order_id"
            >
              订单详情
            </router-link>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import {
  refundList,
  expressWaybill,
  refundDetail,
  expressDelivery,
  cancelRefundApi,
} from "@/api/myOrder";
import { invoiceInvoice } from "@/api/recordInvoice";
import { price } from "@/utils/filters";

const columns = [
  {
    title: "售后编号",
    dataIndex: "return_order_sn",
    key: "return_order_sn",
    width: 180,
    align: "center",
  },
  {
    title: "订单编号",
    dataIndex: "order_id",
    key: "order_id",
    align: "center",
    width: 170,
    scopedSlots: { customRender: "orderId" },
  },
  {
    title: "售后商品",
    dataIndex: "product",
    key: "product",
    align: "center",
    width: 250,
    scopedSlots: { customRender: "product" },
  },
  {
    title: "售后类型",
    dataIndex: "type",
    key: "type",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "type" },
  },
  {
    title: "售后状态",
    dataIndex: "return_status",
    key: "return_status",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "return_status" },
  },
  {
    title: "退款金额",
    dataIndex: "price",
    key: "price",
    align: "center",
    scopedSlots: { customRender: "price" },
    width: 150,
  },
  // {
  //   title: "开票状态",
  //   dataIndex: "billingState",
  //   key: "billingState",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "billingState" },
  // },
  {
    title: "订单状态",
    dataIndex: "orderStatus",
    key: "orderStatus",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "orderStatus" },
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    align: "center",
    width: 120,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "refundOrder",
  components: { PageLayout },
  filters: { price },
  data() {
    return {
      loading: false,
      columns,
      data: [],
      query: {
        page: 1,
        limit: 10,
        refundType: 0,
      },
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      progressModal: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      form: {},
      wayList: [],
      infoList: [{}], //输入的快递信息
      refundDetails: {},
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getQuery() {
      this.query.page = 1;
      this.getList();
    },
    changeDateTime(date, dateString) {
      this.query.applyTime =
        dateString.join("/") == "/" ? "" : dateString.join("/");
    },
    submitInfo() {
      console.log(this.infoList, this.refundDetails.return_order_sn);
      let params = {
        expressInfo: "",
        refundOrderSn: this.refundDetails.return_order_sn,
      };
      let list = [];
      this.infoList.map((item) => {
        list.push(item.name + "|" + item.number + "|0");
      });
      params.expressInfo = list.join(",");
      expressWaybill(params).then(() => {
        this.getDetail(this.refundDetails.id);
      });
    },
    delInfo(index) {
      this.infoList.splice(index, 1);
    },
    addInfo() {
      this.infoList.push({});
    },
    selectName(e, index, name) {
      let value = name == "number" ? e.target.value : e;
      this.$set(this.infoList[index], name, value);
    },
    getexpressDelivery() {
      const hide = this.$message.loading("查询中", 0);
      expressDelivery()
        .then((res) => {
          this.wayList = res.data.data;
          this.progressModal = true;
        })
        .finally(() => {
          hide();
        });
    },
    handleCancel() {
      this.progressModal = false;
    },
    getDetail(id) {
      refundDetail(id).then((res) => {
        this.refundDetails = res.data.data;
      });
    },
    toDetail(id) {
      this.getexpressDelivery();
      console.log(id);
      this.getDetail(id);
    },
    clearPage() {
      this.query.page = 1;
    },
    getInvoice() {
      invoiceInvoice(this.query).then((res) => {
        this.invoiceList = res.data.data.list;
      });
    },
    getList() {
      this.loading = true;
      const dict = { ...this.query };
      if (this.query.refundType == 100) {
        dict.refundType = 0;
        dict.returnStatus = 4;
      }
      refundList(dict)
        .then((res) => {
          this.data = res.data.data.list;
          this.total = res.data.data.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onShowSizeChange(current, pageSize) {
      this.query.page = current;
      this.query.limit = pageSize;
      this.getList();
    },
    onChange(page, pageSize) {
      this.query.page = page;
      this.query.limit = pageSize;
      this.getList();
    },
    cancelRefund(record) {
      this.$confirm({
        title: "提示",
        content: "确定要取消售后吗?",
        okText: "取消售后",
        okType: "danger",
        cancelText: "点错了",
        onOk: () => {
          cancelRefundApi({ refundId: record.id }).then(() => {
            this.$message.success("取消售后成功");
            this.getList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "myOrder";

p {
  margin-bottom: 0;
}

.order_type {
  padding: 0 5px;
  color: #fff;
  background-color: #3ab887;
}

.product_box {
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    width: 39px;
    height: 39px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .product_info {
    width: 177px;
    flex-direction: column;

    .product_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
    }

    .product_num {
      display: flex;
      justify-content: space-between;
    }
  }
}

.progress_box {
  position: relative;
  padding-left: 50px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 24px;
}

.progress_box::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  left: 25px;
  top: 2px;
  background-color: #3ab887;
}

.progress_box::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #e5e5e5;
  position: absolute;
  left: 32px;
  top: 17px;
}

.red_cricle::before {
  background-color: #ff5733 !important;
}

.progress_box:last-of-type::after {
  background-color: transparent;
}

.green_title {
  color: #3ab887;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.green_content {
  color: #3ab887;
  font-size: 12px;
  margin-bottom: 5px;
}

.red_title {
  color: #ff5733;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.red_content {
  color: #ff5733;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}

.content_color {
  color: #666666;
  margin-bottom: 5px;
  font-size: 12px;
}

.return_info {
  color: #505050;
  font-size: 12px;
  line-height: 16px;

  span {
    display: block;
    margin-bottom: 5px;
  }
}

.info /deep/ .ant-form label,
.info /deep/ .ant-form-item-label {
  font-size: 12px;
  line-height: 16px;
  color: #505050;
}

div /deep/ .ant-form label {
  font-size: 12px;
  color: #505050;
}

div /deep/ .ant-form-item {
  margin-bottom: 12px;
}

.boder1 {
  border: 1px solid rgb(243, 242, 242);
  margin-bottom: 12px;
  padding-top: 10px;
  position: relative;

  .close {
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 16px;
    cursor: pointer;
  }

  .text_r {
    text-align: right;
  }
}

.add_info {
  color: #3ab887;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb24 {
  margin-bottom: 24px;
}
</style>
